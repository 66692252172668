var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h1",
        { staticStyle: { "margin-left": "2%", "margin-bottom": "4%" } },
        [_vm._v("Messagerie")]
      ),
      _c("ComposeMail", {
        ref: "composeMail",
        attrs: { visible: _vm.visible, processing: _vm.processing },
        on: {
          cancel: _vm.closeModal,
          close: _vm.closeModal,
          create: _vm.handleCreate,
          upload_success: function($event) {
            return _vm.handleUploadedFile($event)
          },
          remove: function($event) {
            return _vm.handleRemovingFile($event)
          }
        }
      }),
      _c(
        "a-row",
        {
          staticClass: "table_head",
          attrs: { type: "flex", justify: "space-between" }
        },
        [
          _c(
            "a-col",
            { staticClass: "mrgin_btm", attrs: { xs: 24, sm: 4 } },
            [
              _c(
                "a-button",
                {
                  staticClass: "editable-add-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.showModal }
                },
                [_vm._v("Nouveau message")]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { staticClass: "mrgin_btm", attrs: { xs: 24, sm: 6 } },
            [
              _c("search-component", {
                on: {
                  change: function($event) {
                    return _vm.searchMessage($event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-tabs",
        {
          style: { height: "100vh" },
          attrs: {
            defaultActiveKey: _vm.active_key,
            tabPosition: _vm.tabPosition,
            size: _vm.size
          },
          on: { change: _vm.callback }
        },
        [
          _c(
            "a-tab-pane",
            { key: "1" },
            [
              _c(
                "span",
                { attrs: { slot: "tab" }, slot: "tab" },
                [
                  _c("a-icon", { attrs: { type: "inbox" } }),
                  _vm._v("\n        Boite de réception\n      ")
                ],
                1
              ),
              _c(
                "a-spin",
                { attrs: { spinning: _vm.processing_received_messages } },
                [
                  _c("EmailsList", {
                    attrs: {
                      data: _vm.received_messages,
                      pagination: _vm.received_messages_pagination,
                      isAdmin: _vm.isAdmin,
                      isBoiteReception: true
                    },
                    on: {
                      show_size_change: _vm.receivedSizeChange,
                      change: _vm.receivedChange
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-tab-pane",
            { key: "2" },
            [
              _c(
                "span",
                { attrs: { slot: "tab" }, slot: "tab" },
                [
                  _c("a-icon", { attrs: { type: "export" } }),
                  _vm._v("\n        Messages envoyées\n      ")
                ],
                1
              ),
              _c(
                "a-spin",
                { attrs: { spinning: _vm.processing_sent_messages } },
                [
                  _c("EmailsList", {
                    attrs: {
                      data: _vm.sent_messages,
                      pagination: _vm.sent_messages_pagination,
                      isAdmin: _vm.isAdmin,
                      isBoiteReception: false
                    },
                    on: {
                      show_size_change: _vm.sentSizeChange,
                      change: _vm.sentChange
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }