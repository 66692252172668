<template>
  <div>
    <h1 style="margin-left: 2%; margin-bottom: 4%">Messagerie</h1>
    <ComposeMail
      ref="composeMail"
      :visible="visible"
      :processing="processing"
      @cancel="closeModal"
      @close="closeModal"
      @create="handleCreate"
      @upload_success="handleUploadedFile($event)"
      @remove="handleRemovingFile($event)"
    />

    <a-row type="flex" justify="space-between" class="table_head">
      <a-col :xs="24" :sm="4" class="mrgin_btm">
        <a-button class="editable-add-btn" type="primary" v-on:click="showModal"
          >Nouveau message</a-button
        >
      </a-col>
      <a-col :xs="24" :sm="6" class="mrgin_btm">
        <search-component v-on:change="searchMessage($event)" />
      </a-col>
    </a-row>

    <a-tabs
      :defaultActiveKey="active_key"
      :tabPosition="tabPosition"
      :size="size"
      :style="{ height: '100vh' }"
      @change="callback"
    >
      <a-tab-pane key="1">
        <span slot="tab">
          <a-icon type="inbox" />
          Boite de réception
        </span>
        <a-spin :spinning="processing_received_messages">
          <EmailsList
            :data="received_messages"
            @show_size_change="receivedSizeChange"
            @change="receivedChange"
            :pagination="received_messages_pagination"
            :isAdmin="isAdmin"
            :isBoiteReception="true"
          ></EmailsList>
        </a-spin>
      </a-tab-pane>
      <a-tab-pane key="2">
        <span slot="tab">
          <a-icon type="export" />
          Messages envoyées
        </span>
        <a-spin :spinning="processing_sent_messages">
          <EmailsList
            :data="sent_messages"
            @show_size_change="sentSizeChange"
            @change="sentChange"
            :pagination="sent_messages_pagination"
            :isAdmin="isAdmin"
            :isBoiteReception="false"
          ></EmailsList>
        </a-spin>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";
import _ from "lodash";

import EmailsList from "@/components/common/messagerie/EmailsList";
import ComposeMail from "@/components/common/messagerie/NewMail";
import constants from "@/const/const";
import SearchComponent from "@/components/common/Search";

const tabPosition = "top";
const size = "smal";
export default {
  name: "AdherentMessagerieIndex",
  components: { ComposeMail, EmailsList, SearchComponent },
  created() {
    this.fetchSentMessages();
    this.fetchReceivedMessages();
  },
  data() {
    this.searchMessage = _.debounce(this.searchMessage, 500);
    return {
      tabPosition,
      size,
      visible: false,
      processing: false,
      errors: false,
      processing_received_messages: false,
      processing_sent_messages: false,
      isAdmin: false,
      BOITE_RECEPTION: 1,
      SENT_MESSAGES: 2,
      q: "",
    };
  },
  watch: {
    q: function (oldValue, newValue) {
      if (Number(this.active_key) === this.BOITE_RECEPTION) {
        // Boite reception
        this.fetchReceivedMessages();
      } else {
        this.fetchSentMessages();
      }
    },
  },
  computed: {
    ...mapState({
      received_messages: (state) => state.messageries.received_messages,
      sent_messages: (state) => state.messageries.sent_messages,
      received_messages_pagination: (state) =>
        state.messageries.received_messages_pagination,
      sent_messages_pagination: (state) =>
        state.messageries.sent_messages_pagination,
    }),
    ...mapGetters({
      active_key: "getSelectedTabKey",
    }),
  },
  methods: {
    getPrams() {
      return {
        q: this.q,
      };
    },
    searchMessage(e) {
      this.q = e;
    },
    receivedSizeChange(size) {
      this.setReceivedMessagesPagination({
        ...this.received_messages_pagination,
        pageSize: size,
      });
      this.fetchReceivedMessages();
    },
    receivedChange(pagination) {
      this.setReceivedMessagesPagination({
        ...this.received_messages_pagination,
        current: pagination,
      });
      this.fetchReceivedMessages();
    },
    sentSizeChange(size) {
      this.setSentMessagesPagination({
        ...this.sent_messages_pagination,
        pageSize: size,
      });
      this.fetchSentMessages();
    },
    sentChange(pagination) {
      this.setSentMessagesPagination({
        ...this.sent_messages_pagination,
        current: pagination,
      });
      this.fetchSentMessages();
    },
    handleRemovingFile($event) {
      this.destroyFile($event);
    },
    fetchSentMessages() {
      // this.processing_sent_messages = true;
      this.getSentMessages(this.getPrams());

      // .then(() => {
      //     this.processing_received_messages = false;
      // })
      // .catch(() => (this.processing_received_messages = false));
    },
    fetchReceivedMessages() {
      this.getReceivedMessages(this.getPrams());
    },
    closeModal() {
      this.clearAttachedFiles();
      this.visible = false;
    },
    showModal() {
      this.visible = true;
    },
    handleUploadedFile($event) {
      this.addAttachementFile($event);
    },
    // handleRemovingFile($event) {
    //   this.destroyFile($event);
    // },
    handleCreate() {
      const form = this.$refs.composeMail.form;
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        this.processing = true;
        let email = {};
        email.subject = values.subject;
        email.message = values.message;
        email.attachements = this.getAttachementFiles();

        this.send(email)
          .then(() => {
            this.fetchSentMessages();
            this.$_showSuccessMessage(`${constants.MESSAGE_SENT}`, 5);
            this.closeModal();
            this.processing = false;
            form.resetFields();
          })
          .catch(() => {
            this.$_throwAnError(`${constants.MESSAGE_SENT_FAIL}`, 5);
            this.processing = false;
          });
      });
    },
    callback(val) {
      this.setSelectedTabKey(val);
    },
    displayErrors(state) {
      this.errors = state;
    },
    ...mapActions({
      getSentMessages: "fetchSentMessages",
      getReceivedMessages: "fetchReceivedMessages",
      destroyFile: "destroyAttachedFile",
      send: "sendMessage",
    }),
    ...mapMutations({
      addAttachementFile: "addAttachementsFileId",
      clearAttachedFiles: "clearAttachedFilesId",
      setSelectedTabKey: "setSelectedTabKey",
      setReceivedMessagesPagination: "setReceivedMessagesPagination",
      setSentMessagesPagination: "setSentMessagesPagination",
    }),
    ...mapGetters({ getAttachementFiles: "getAttachementsIds" }),
    moment,
  },
};
</script>

<style scoped>
.mrgin_btm {
  margin-bottom: 4%;
}
</style>